<template>
  <div class="pages">
    <div class="commission-info">
      <div class="info-title">
        <div class="title">
          <span class="line"></span>
          <span>结佣申请记录</span>
        </div>
      </div>
    </div>
    <div class="record-list" v-loading="loading">
      <div class="list" v-for="(item,index) in list" :key="index">
        <img :src="item.link" alt="">
      </div>
    </div>
<!--    <div class="paging-info">-->
<!--      <div class="pagination">-->
<!--        <div class="num" :class="{active:item === page.current}" v-for="item in page.last" :key="item"-->
<!--             @click="handlePageClick(item)">-->
<!--          <span>{{ item }}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="paging">-->
<!--        <div class="cut" @click="handlePagePrevClick" v-show="page.current !== 1">-->
<!--          <span class="left"></span>-->
<!--        </div>-->
<!--        <div class="cut" @click="handlePageNextClick" v-show="page.current !== page.last">-->
<!--          <span class="right"></span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "CommissionRecord",
  data(){
    return {
      loading: false,
      list: [],
      page: {
        current: 1,
        last: 1,
      },
    }
  },
  mounted() {
    this.getData();
  },
  methods:{
    async getData(){
      this.loading = true
      let res = await this.http({
        url: '/api/my/commissionApplication',
        method: 'GET',
        params: {
          page: this.page.current,
          limit: 6,
        }
      })
      this.list = res.data;
      this.loading = false;
    },
    handlePageClick(page) {
      this.page.current = page
      this.getData()
    },
    handlePagePrevClick() {
      this.page.current--
      this.getData()
    },
    handlePageNextClick() {
      this.page.current++
      this.getData()
    },
    handleSearchClick() {
      this.page.current = 1
      this.getData()
    },
  }
}
</script>

<style scoped lang="scss">
@import "CommissionRecord.scss";
</style>
